import { NewToken } from './components/new-token';
import { deleteVideoLink, saveVideoLink } from '../../../../service';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../../../components/input';
import { Card } from '../../../../components/card';
import { EvaluationsList } from '../../../../components/evaluations-list';
import { VideoRender } from '../../../../components/video-render';
import { progressObject } from '../../../../components/filter-component/options';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useLoading } from '../../../../hooks/use-loading';
import Button from '../../../../components/common/Button';
import { Paragraph } from '../../../../components/common/Paragraph';
import { EmphasisTitle } from '../../../../components/common/EmphasisTitle';
import { capitalizeWords } from '../../../../hooks/captalize-words';
import { validateVideoLink } from '../../../../util/validate-video-link';

export interface World {
  name: string;
  image: string;
}

interface VideoInfo {
  videoId?: string;
  videoEmail?: string;
  videoLink?: string;
  videoStatus?: string;
  videoService?: string;
  totalVideoScore?: number;
  videoMissionName?: string;
}

interface Mission {
  name: string;
  world?: World;
  video?: VideoInfo;
  completed?: boolean;
  evaluations?: {
    approvedContent: boolean;
    approvedDuration: boolean;
    approvedOriginality: boolean;
  };
  tokens: number[];
  number: number;
  virtualPoints?: number;
  finalPoints?: number;
}

interface MissionCardNewProps {
  disabled?: boolean;
  gameUrlParam: string | undefined;
  game: string;
  world?: World;
  mission?: Mission;
  refetch: () => void;
}

export const MissionCardNew = ({
  world,
  mission,
  disabled,
  game,
  refetch,
  gameUrlParam,
}: MissionCardNewProps) => {
  const [videoLink, setVideoLink] = useState('');
  const videoRenderParentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const gameData = React.useMemo(() => {
    const worldName = 'O ' + world?.name!;
    const gameData = progressObject[worldName][game];
    return gameData;
  }, [game, world]);

  const { mutate: saveVideo, isPending: isSaving } = useMutation({
    mutationFn: async () =>
      await saveVideoLink(
        videoLink,
        localStorage.getItem('city')!,
        'O ' + world?.name!,
        gameData.mission,
        game,
        mission?.virtualPoints!,
      ),
    onSuccess: () => {
      refetch();
      toast.success('Vídeo salvo com sucesso!');
    },
    onError: (error: string) => {
      toast.error('Erro ao salvar vídeo');
    },
  });
  const { mutate: deleteVideo, isPending: isDeleting } = useMutation({
    mutationFn: async () =>
      await deleteVideoLink(mission?.video?.videoId!, mission?.video?.videoEmail!),
    onSuccess: () => {
      refetch();
      toast.success('Vídeo deletado com sucesso!');
    },
    onError: (error: string) => {
      toast.error('Erro ao deletar vídeo');
    },
  });

  useEffect(() => {
    if (isSaving || isDeleting) setIsLoading(true);
    else setIsLoading(false);
  }, [isSaving, isDeleting, setIsLoading]);

  return (
    <Card disabled={disabled} className="gap-6">
      <EmphasisTitle className="text-center">{world?.name}</EmphasisTitle>
      <img className="w-60" src={world?.image} alt={world?.name} />
      <div className="grid w-full grid-cols-3 gap-4">
        <div className="flex flex-col gap-2">
          <NewToken value={mission?.tokens[0]} />
          <NewToken value={mission?.tokens[1]} />
        </div>
        <div className="flex flex-col gap-2">
          <NewToken value={mission?.tokens[2]} />
          <NewToken value={mission?.tokens[3]} />
        </div>
        <div className="flex flex-col gap-2">
          <NewToken value={mission?.tokens[4]} />
          <NewToken value={mission?.tokens[5]} />
        </div>
      </div>
      {!mission?.completed || disabled ? (
        <div className="flex w-full flex-col items-center justify-between gap-8">
          <Paragraph className="text-center font-semibold md:text-2xl">
            Você ainda não completou os desafios de {game}. Complete todos os desafios para poder
            enviar o vídeo da missão.
          </Paragraph>
          <Button onClick={() => navigate(`/game/${gameUrlParam}`)} value="Jogar" />
        </div>
      ) : mission?.video?.videoLink && !disabled && mission.video?.videoStatus !== 'D' ? (
        <>
          <div className="flex gap-2">
            <p className="text-2xl font-extrabold">Missão:</p>
            <p className="text-2xl">{mission?.name}</p>
          </div>
          <div className="relative w-full" ref={videoRenderParentRef}>
            <img
              src={`img/trash.png`}
              onClick={() => deleteVideo()}
              className="absolute right-2 top-2 z-10 w-16 cursor-pointer"
              alt=""
            />
            <VideoRender
              parentRef={videoRenderParentRef}
              service={mission.video?.videoService || 'youtube'}
              video={mission.video.videoLink}
            />
          </div>
          <EvaluationsList
            evaluations={{
              content: mission.evaluations?.approvedContent,
              duration: mission.evaluations?.approvedDuration,
              originality: mission.evaluations?.approvedOriginality,
            }}
          />
          <div className="flex w-full flex-col items-start gap-4">
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Avatar:</p>
              <p className="text-2xl">{localStorage.getItem('avatarName')}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Cidade:</p>
              <p className="text-2xl">{capitalizeWords(localStorage.getItem('city') || '')}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Mundo:</p>
              <p className="text-2xl">{world?.name}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Jogo:</p>
              <p className="text-2xl">{capitalizeWords(game)}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Missão:</p>
              <p className="text-2xl">{mission?.video?.videoMissionName}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl">
                <b>Status do vídeo: </b>
                {mission.video?.videoStatus}
              </p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação virtual:</p>
              <p className="text-2xl">{mission?.virtualPoints}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação do vídeo:</p>
              <p className="text-2xl">{mission?.video?.totalVideoScore}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação total:</p>
              <p className="text-2xl">{mission?.finalPoints}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <Paragraph className="text-center font-semibold md:text-2xl">
            Parabéns! você completou os desafios de {mission?.name}. Agora vamos para a missão?
            Insira abaixo o endereço do vídeo de sua missão e clique em salvar.
          </Paragraph>
          <div className="flex w-full gap-4">
            <Input
              className="h-16 border-none bg-white"
              onChange={(e) => setVideoLink(e.target.value)}
              value={videoLink}
            />
            <Button
              className="text-2xl"
              onClick={() => {
                const validatedUrl = validateVideoLink(videoLink);
                if (validatedUrl !== 'youtube' && validatedUrl !== 'tiktok') {
                  return toast.error('Link inválido');
                }
                saveVideo();
              }}
              value="Salvar"
            />
          </div>
          <Paragraph className="text-center font-semibold md:text-3xl">
            Mas... qual é a missão mesmo?
          </Paragraph>
          <Button
            className="w-full text-2xl"
            onClick={() => window.open(gameData.video, '_blank')}
            value="Veja novamente o vídeo da missão"
          />
          <Button
            className="w-full bg-orange-400 text-2xl"
            onClick={() => window.open('https://youtu.be/RZ6NjGFQTtM?feature=shared', '_blank')}
            value="Veja como inserir o vídeo da missão"
          />
        </>
      )}
    </Card>
  );
};
