import React from 'react';
import { Card } from '../../../../../components/card';
import { EvaluationsList } from '../../../../../components/evaluations-list';
import { Token } from '../../../../new-video-gallery/components/mission-card/components/token';
import DeleteConfirm from '../../../../../components/common/DeleteConfirm';
import { capitalizeAfterSpace } from '../../../../../util/capitalizeString';
import { progressObject } from '../../../../../components/filter-component/options';
import { MdCancel, MdEdit, MdSave } from 'react-icons/md';
import { cn } from '../../../../../util/cn';
import { Input } from '../../../../../components/input';
import Button from '../../../../../components/common/Button';
import { capitalizeWords } from '../../../../../hooks/captalize-words';
import { validateVideoLink } from '../../../../../util/validate-video-link';
import { toast } from 'react-toastify';

interface IStudentCardProgressProps {
  classType: string;
  student: any;
  students: any;
  classCode?: string;
  editingStudent?: any;
  handleEdit?: (student: any) => void;
  handleSaveProgress?: () => void;
  handleDelete: (studentId: string) => void;
  handleResetPassword: (studentId: string, classCode: string) => Promise<boolean | undefined>;
  handleSaveVideo?: (
    videoLink: string,
    city: string,
    worldName: string,
    missionName: string,
    gameName: string,
    virtualPoints: number,
    userId: number,
    classCode: string,
  ) => Promise<boolean | undefined>;
  handleDeleteVideo?: (videoId: string) => void;
}
export const StudentCardProgress: React.FC<IStudentCardProgressProps> = ({
  classType,
  student,
  editingStudent,
  handleEdit,
  handleSaveProgress,
  handleDelete,
  handleResetPassword,
  handleSaveVideo,
  handleDeleteVideo,
}: any) => {
  const [videoLinks, setVideoLinks] = React.useState<Record<string, string>>({});
  const videoRenderParentRef = React.useRef<HTMLDivElement>(null);
  const enabledGames = React.useMemo(() => {
    return ['Reúso de Água', 'A história do Saneamento', 'Resíduos Orgânicos'];
  }, []);

  const calculateTotalScore = React.useCallback((tokens: number[]): number => {
    const bronze = (tokens[0] === 1 ? 570 : 0) + (tokens[1] === 1 ? 580 : 0);
    const silver = (tokens[2] === 2 ? 950 : 0) + (tokens[3] === 2 ? 1030 : 0);
    const gold = (tokens[4] === 3 ? 1280 : 0) + (tokens[5] === 3 ? 1590 : 0);
    return bronze + silver + gold;
  }, []);

  const handleVideoLinkChange = (gameId: string, value: string) => {
    setVideoLinks((prev) => ({
      ...prev,
      [gameId]: value,
    }));
  };

  return (
    <div>
      <div className="mb-12 flex w-full flex-col gap-4">
        <div className="flex flex-row items-center gap-20">
          <div className="flex flex-row gap-4">
            <p className="text-3xl font-extrabold">Nome do aluno:</p>
            <p className="text-3xl font-semibold">
              {capitalizeAfterSpace(student?.userName) || '-'}
            </p>
            <p className="text-3xl">|</p>
            <p className="text-3xl font-extrabold">Nome do avatar:</p>
            <p className="text-3xl font-semibold">{student?.nameAvatar || '-'}</p>
          </div>
          <div className="flex flex-row gap-4">
            <DeleteConfirm
              onClick={() => handleResetPassword(student?.userId, student?.classCode)}
              buttonName="Apagar senha"
              messageTitle="Apagar senha"
              messageText="Tem certeza que deseja apagar a senha do usuário? O usuário deverá utilizar o Código de Turma e criar uma nova senha."
              buttonProps={
                'h-14 bg-[#009C9D] text-2xl disabled:cursor-not-allowed disabled:opacity-50'
              }
              disabled={student?.userPassword === 'clearPassword'}
            />
            <DeleteConfirm
              onClick={() => handleDelete(student?.userId)}
              buttonProps={'h-14 bg-zinc-400 text-2xl'}
              buttonName="Apagar aluno"
              messageTitle="Apagar aluno"
              messageText="Tem certeza que deseja apagar o aluno?"
            />
          </div>
        </div>
      </div>
      <div className="grid w-full grid-cols-3 gap-4">
        {student.worlds &&
          Object.entries(student.worlds).map(([worldName, worldData]: [string, any], idxWorld) => (
            <div className="flex flex-col gap-6" key={idxWorld}>
              <div className="flex w-full flex-col items-start">
                {worldData &&
                  Object.entries(worldData.games).map(
                    ([gameName, gameData]: [string, any], gameIdx) => {
                      if (!enabledGames.includes(gameName)) return null;
                      return (
                        <Card
                          key={gameIdx}
                          className={cn(
                            'relative mb-4 gap-8',
                            editingStudent &&
                              gameData.gameId === editingStudent?.gameId &&
                              'bg-zinc-300',
                          )}
                        >
                          <div
                            className={cn(
                              'absolute right-4 top-4',
                              classType === 'Online' && 'hidden',
                            )}
                          >
                            {editingStudent && gameData.gameId === editingStudent?.gameId ? (
                              <div className={cn('flex flex-row gap-2')}>
                                <MdCancel
                                  className="z-20 cursor-pointer items-center justify-center text-5xl"
                                  onClick={() => handleEdit(null)}
                                />

                                <MdSave
                                  className="z-20 cursor-pointer items-center justify-center text-5xl"
                                  onClick={handleSaveProgress}
                                />
                              </div>
                            ) : (
                              <MdEdit
                                className="z-20 cursor-pointer items-center justify-center text-5xl"
                                onClick={() => {
                                  handleEdit({
                                    ...gameData,
                                    studentId: student.userId,
                                    worldId: worldData.worldId,
                                  });
                                }}
                              />
                            )}
                          </div>
                          <div className="flex w-full flex-col gap-2" key={gameIdx}>
                            <div className="flex gap-2">
                              <p className="text-2xl">
                                <b>Mundo: </b>
                                {worldName}
                              </p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl">
                                <b>Jogo: </b>
                                {capitalizeWords(gameName) || '-'}
                              </p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Progressão do jogo virtual:</p>
                            </div>
                            {gameData && (
                              <div className="my-4 grid w-full grid-cols-3 gap-4">
                                <div className="flex flex-col gap-2">
                                  <Token
                                    className={cn(
                                      'cursor-pointer',
                                      !editingStudent && 'cursor-not-allowed',
                                    )}
                                    value={
                                      editingStudent && editingStudent?.gameId === gameData?.gameId
                                        ? editingStudent.tokens[0]
                                        : gameData?.tokens[0]
                                    }
                                    onClick={() => {
                                      if (!editingStudent) return;
                                      handleEdit((prev: any) => {
                                        const tokens = [
                                          prev.tokens[0] === 0 ? 1 : 0,
                                          prev.tokens[1],
                                          prev.tokens[2],
                                          prev.tokens[3],
                                          prev.tokens[4],
                                          prev.tokens[5],
                                        ];
                                        return {
                                          ...prev,
                                          totalGameScore: calculateTotalScore(tokens),
                                          tokens: tokens,
                                        };
                                      });
                                    }}
                                  />
                                  <Token
                                    className={cn(
                                      'cursor-pointer',
                                      !editingStudent && 'cursor-not-allowed',
                                    )}
                                    value={
                                      editingStudent && editingStudent?.gameId === gameData?.gameId
                                        ? editingStudent.tokens[1]
                                        : gameData?.tokens[1]
                                    }
                                    onClick={() => {
                                      if (!editingStudent) return;
                                      handleEdit((prev: any) => {
                                        const tokens = [
                                          prev.tokens[0],
                                          prev.tokens[1] === 0 ? 1 : 0,
                                          prev.tokens[2],
                                          prev.tokens[3],
                                          prev.tokens[4],
                                          prev.tokens[5],
                                        ];
                                        return {
                                          ...prev,
                                          totalGameScore: calculateTotalScore(tokens),
                                          tokens: tokens,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                                <div className="flex flex-col gap-2">
                                  <Token
                                    className={cn(
                                      'cursor-pointer',
                                      !editingStudent && 'cursor-not-allowed',
                                    )}
                                    value={
                                      editingStudent && editingStudent?.gameId === gameData?.gameId
                                        ? editingStudent.tokens[2]
                                        : gameData?.tokens[2]
                                    }
                                    onClick={() => {
                                      if (!editingStudent) return;
                                      handleEdit((prev: any) => {
                                        const tokens = [
                                          prev.tokens[0],
                                          prev.tokens[1],
                                          prev.tokens[2] === 0 ? 2 : 0,
                                          prev.tokens[3],
                                          prev.tokens[4],
                                          prev.tokens[5],
                                        ];
                                        return {
                                          ...prev,
                                          totalGameScore: calculateTotalScore(tokens),
                                          tokens: tokens,
                                        };
                                      });
                                    }}
                                  />
                                  <Token
                                    className={cn(
                                      'cursor-pointer',
                                      !editingStudent && 'cursor-not-allowed',
                                    )}
                                    value={
                                      editingStudent && editingStudent?.gameId === gameData?.gameId
                                        ? editingStudent.tokens[3]
                                        : gameData?.tokens[3]
                                    }
                                    onClick={() => {
                                      if (!editingStudent) return;
                                      handleEdit((prev: any) => {
                                        const tokens = [
                                          prev.tokens[0],
                                          prev.tokens[1],
                                          prev.tokens[2],
                                          prev.tokens[3] === 0 ? 2 : 0,
                                          prev.tokens[4],
                                          prev.tokens[5],
                                        ];
                                        return {
                                          ...prev,
                                          totalGameScore: calculateTotalScore(tokens),
                                          tokens: tokens,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                                <div className="flex flex-col gap-2">
                                  <Token
                                    className={cn(
                                      'cursor-pointer',
                                      !editingStudent && 'cursor-not-allowed',
                                    )}
                                    value={
                                      editingStudent && editingStudent?.gameId === gameData?.gameId
                                        ? editingStudent.tokens[4]
                                        : gameData?.tokens[4]
                                    }
                                    onClick={() => {
                                      if (!editingStudent) return;
                                      handleEdit((prev: any) => {
                                        const tokens = [
                                          prev.tokens[0],
                                          prev.tokens[1],
                                          prev.tokens[2],
                                          prev.tokens[3],
                                          prev.tokens[4] === 0 ? 3 : 0,
                                          prev.tokens[5],
                                        ];
                                        return {
                                          ...prev,
                                          totalGameScore: calculateTotalScore(tokens),
                                          tokens: tokens,
                                        };
                                      });
                                    }}
                                  />
                                  <Token
                                    className={cn(
                                      'cursor-pointer',
                                      !editingStudent && 'cursor-not-allowed',
                                    )}
                                    value={
                                      editingStudent && editingStudent?.gameId === gameData?.gameId
                                        ? editingStudent.tokens[5]
                                        : gameData?.tokens[5]
                                    }
                                    onClick={() => {
                                      if (!editingStudent) return;
                                      handleEdit((prev: any) => {
                                        const tokens = [
                                          prev.tokens[0],
                                          prev.tokens[1],
                                          prev.tokens[2],
                                          prev.tokens[3],
                                          prev.tokens[4],
                                          prev.tokens[5] === 0 ? 3 : 0,
                                        ];
                                        return {
                                          ...prev,
                                          totalGameScore: calculateTotalScore(tokens),
                                          tokens: tokens,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="mt-4 flex gap-2">
                              <p className="text-2xl">
                                <b>Pontuação virtual: </b>
                                {editingStudent && editingStudent.gameId === gameData.gameId
                                  ? editingStudent.totalGameScore
                                  : gameData.totalGameScore}
                              </p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl">
                                <b>Missão video: </b>
                                {gameData.videoMissionName ||
                                  progressObject[worldName][gameName].mission ||
                                  '-'}
                              </p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl">
                                <b>Status do video: </b>
                                {gameData.finishedGame && !gameData.videoLink
                                  ? 'liberado para envio:'
                                  : gameData.videoStatus}
                              </p>
                            </div>
                            {gameData.finishedGame && !gameData.videoLink && (
                              <div className="my-4 flex flex-col gap-4">
                                <div className="flex w-full gap-4">
                                  <Input
                                    className="h-16 border-none bg-white"
                                    onChange={(e) =>
                                      handleVideoLinkChange(gameData.gameId, e.target.value)
                                    }
                                    value={videoLinks[gameData.gameId] || ''}
                                  />
                                  <Button
                                    className="text-2xl"
                                    onClick={async () => {
                                      const saved = await handleSaveVideo(
                                        videoLinks[gameData.gameId] || '',
                                        student.userCity,
                                        worldName,
                                        progressObject[worldName][gameName].mission,
                                        gameName,
                                        gameData.totalGameScore,
                                        student.userId,
                                        student.classCode,
                                      );
                                      if (saved) handleVideoLinkChange(gameData.gameId, '');
                                    }}
                                    value="Salvar"
                                  />
                                </div>
                              </div>
                            )}
                            {gameData.videoLink && (
                              <div
                                className="relative flex w-full flex-row gap-4"
                                ref={videoRenderParentRef}
                              >
                                <Button
                                  className="h-14 bg-[#009C9D] text-2xl"
                                  value="Ver vídeo"
                                  onClick={() => {
                                    const videoOrigin = validateVideoLink(gameData.videoLink);
                                    if (videoOrigin === 'Link do vídeo inválido') {
                                      toast.error('Link do video inválido');
                                      return;
                                    }
                                    window.open(gameData.videoLink, '_blank');
                                  }}
                                />
                                <Button
                                  className="h-14 bg-zinc-400 text-2xl"
                                  onClick={() => handleDeleteVideo(gameData.videoId)}
                                  value="Apagar vídeo"
                                />
                              </div>
                            )}
                            {gameData.videoLink && (
                              <div className="my-6 flex w-full flex-col">
                                <EvaluationsList
                                  evaluations={{
                                    content: gameData.approvedContent,
                                    duration: gameData.approvedDuration,
                                    originality: gameData.approvedOriginality,
                                  }}
                                />
                              </div>
                            )}
                            <div className="flex gap-2">
                              <p className="text-2xl">
                                <b>Pontuação do video: </b>
                                {gameData.totalVideoScore}
                              </p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl">
                                <b>Pontuação total: </b>
                                {(editingStudent && editingStudent.gameId === gameData.gameId
                                  ? editingStudent.totalGameScore
                                  : gameData.totalGameScore || 0) + (gameData.totalVideoScore || 0)}
                              </p>
                            </div>
                          </div>
                        </Card>
                      );
                    },
                  )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
